import React, { useState, useEffect } from "react";
import { Button, DatePicker, Dropdown, Space } from "antd";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { LiaTimesSolid } from "react-icons/lia";
import { MdCheck, MdKeyboardArrowDown } from "react-icons/md";
import GlobalLoader from "../../../components/GlobalLoader.jsx";
import { AiFillEdit } from "react-icons/ai";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { Link, useNavigate, useParams } from "react-router-dom";

import AntModal from "../../../components/AntModal.jsx";
import { getDate } from "../../../utils/index.js";
import {
  useAllSpecialRequest,
  useDeleteSpecialRequest,
  useUpdateSpecialRequest,
} from "../../../features/Special-request/specialRequestServices.js";
import DeleteBtn from "../../../components/DeleteBtn.jsx";
import ApproveBtn from "../../../components/ApproveBtn.jsx";

const Pending = () => {
  const {
    data: requests,
    isLoading,
    error,
  } = useAllSpecialRequest("/api/special-requests");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isShowDetail, setIsShowDetail] = useState({});
  const [isToggled, setIsToggled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAction, setIsAction] = useState("approve");
  const [actionType, setActionType] = useState("approve");
  const navigate = useNavigate();
  const updateMutation = useUpdateSpecialRequest();

  const transformOrders =
    requests?.map((request, index) => ({
      _id: request?._id,
      action: request?._id,
    })) ?? [];

  console.log(transformOrders);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [status, setStatus] = useState("pending");
  const [activeTab, setActiveTab] = useState(0); // To keep track of the active tab
  const toast = useToast();

  const createDeleteMutation = useDeleteSpecialRequest();

  const handleConfirm = async () => {
    setIsModalVisible(false);
    if (isAction === "approve") {
      const data = {
        status: status,
      };

      updateMutation.mutate(
        {
          apiUrl: `/api/special-request/${selectedIdEdit}`,
          data,
        },
        {
          onSuccess: () => {
            toast({
              title: "special-request Status Updated successfully!",
              status: "success",
              duration: 3000,
            });
            setSelectedIdEdit(null);

            console.log("updated");
          },
          onError: (error) => {
            toast({
              title: error.response?.data?.details?.includes("duplicate")
                ? "special-request Status Already Exist"
                : error.response?.data?.details ||
                  error.message ||
                  "Error Updating special-request status",
              status: "error",
              duration: 3000,
            });
            setSelectedIdEdit(null);
            console.log(error.message);
          },
        },
      );
    } else {
      const data = {
        status: status,
      };

      updateMutation.mutate(
        {
          apiUrl: `/api/special-request/${selectedIdEdit}`,
          data,
        },
        {
          onSuccess: () => {
            toast({
              title: "special-request Status Updated successfully!",
              status: "success",
              duration: 3000,
            });
            setSelectedIdEdit(null);

            console.log("updated");
          },
          onError: (error) => {
            toast({
              title: error.response?.data?.details?.includes("duplicate")
                ? "special-requestStatus Already Exist"
                : error.response?.data?.details ||
                  error.message ||
                  "Error Updating special-requeststatus",
              status: "error",
              duration: 3000,
            });
            setSelectedIdEdit(null);
            console.log(error.message);
          },
        },
      );
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActionType(null);
    setSelectedId(null);
  };

  // useEffect(() => {
  //   if (selectedId) {
  //     handleDelete();
  //   }

  // }, [selectedId]);

  const btnComponent = ({ ...props }) => {
    console.log(props);
    const handle = () => {
      setSelectedIdEdit(props?.data._id);
      setStatus("complete");
      setIsAction("approve");
      setIsModalVisible(true);
      setActionType("approve");
    };

    const handleDel = () => {
      // setSelectedId(props?.data?.action);
      setSelectedIdEdit(props?.data?._id);
      console.log(props?.data?._id);
      setStatus("delete");
      setActionType("delete");
      setIsAction("delete");
      setIsModalVisible(true);
    };

    return (
      <div className="flex gap-5 pt-2">
        {/* <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={() => onRowClicked}
        >
          <AiFillEdit />
        </button> */}

        <DeleteBtn onClick={handleDel} />
        <ApproveBtn onClick={handle} />
      </div>
    );
  };

  const columns = [
    {
      headerName: "Date Requested",
      valueGetter: (params) => getDate(params?.data.createdAt),
    },
    {
      headerName: "_id",
      field: "_id",
      minWidth: 150,
      hide: true,
    },

    {
      headerName: "Acquisition Date",
      valueGetter: (params) =>
        getDate(
          params?.data?.order?.puppies[0]?.puppyInformation?.dateAcquired,
        ),
    },
    {
      headerName: "Scheduled Date",
      valueGetter: (params) =>
        getDate(params?.data?.requestInformation?.scheduledDate),
    },
    {
      headerName: "Location",
      valueGetter: (params) => params?.data?.order?.destinationAddress?.address,
    },
    {
      headerName: "Name",
      valueGetter: (params) =>
        params?.data?.order?.destinationAddress?.firstName,
    },
    {
      headerName: "Chip",
      valueGetter: (params) =>
        params?.data?.order?.puppies[0]?.puppyInformation?.idNumber,
    },
    {
      headerName: "Breed",
      valueGetter: (params) =>
        params?.data.order?.puppies[0]?.puppyInformation?.breed?.breedInformation
          .breedName,
    },
    {
      headerName: "Date Of Birth",
      valueGetter: (params) =>
        getDate(params?.data?.order?.puppies[0]?.puppyInformation?.dateOfBirth),
    },
    {
      headerName: "Request Information",
      field: "requestInformation.video",
    },
    {
      headerName: "Birth Certificate",
      field: "requestInformation.birthCertificate",
    },
    {
      headerName: "Sent",
      field: "requestInformation.sent",
    },
    {
      headerName: "Asap",
      field: "requestInformation.asap",
    },
    {
      headerName: "Photo",
      field: "requestInformation.photo",
    },
    {
      headerName: "Face Time",
      field: "requestInformation.faceTime",
    },
    {
      headerName: "Email",
      valueGetter: (params) => params?.data?.customerPhotos?.email,
    },
    {
      headerName: "Phone",
      valueGetter: (params) =>
        params?.data?.order?.contactInformation?.cellPhone,
    },
    {
      headerName: "Notes",
      valueGetter: (params) => params?.data?.requestInformation?.notes,
    },
    {
      headerName: "Last Name",
      valueGetter: (params) => params?.data?.customerPhotos?.lastName,
    },
    {
      headerName: "Pup Sold To",
      valueGetter: (params) =>
        params?.data?.order?.destinationAddress?.firstName,
    },
    {
      headerName: "Price",
      valueGetter: (params) => params?.data?.cost?.price,
    },
    // {
    //   headerName: "Payment",
    //   valueGetter: (params) => params?.data?.cost?.payment,
    // },
    {
      headerName: "Remaining",
      field: "cost.chargeRemaining",
    },
    {
      headerName: "Date",
      valueGetter: (params) => getDate(params?.data?.createdAt),
    },
    { headerName: "Action", cellRenderer: btnComponent, pinned: 'right' , width: '100%' },
  ];

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
  };

  const items = [
    { label: "1st menu item", key: "1" },
    { label: "2nd menu item", key: "2" },
    { label: "3rd menu item", key: "3", danger: true },
    { label: "4th menu item", key: "4", danger: true, disabled: true },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  const getFilteredOrders = (status) => {
    return requests?.filter((order) => order.status === status);
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading orders</p>;

  return (
    <div className="mt-4">
      <Tabs onChange={(index) => setActiveTab(index)}>
        <TabPanel>
          <div
            className="ag-theme-quartz"
            style={{ height: 700, overflowX: "auto" }}
          >
            <AgGridReact
              modules={[ClientSideRowModelModule]}
              rowData={getFilteredOrders("pending")}
              columnDefs={columns}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              pagination={true}
              paginationPageSize={50}
              paginationPageSizeSelector={[10, 25, 50]}
              // domLayout="autoHeight"
              // onRowClicked={onRowClicked}
            />
          </div>
        </TabPanel>
      </Tabs>

      <AntModal
        centered
        isOpen={isModalVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={
          actionType === "approve" ? "Confirm Approval" : "Confirm Deletion"
        }
        content={
          actionType === "approve"
            ? "Are you sure you want to approve this Status?"
            : "Are you sure you want to delete this Special Request?"
        }
        okText={"Confirm"}
      />
    </div>
  );
};

export default Pending;
